import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

//image
import PartnerSectionImg1 from '../../assets/img/partner-section-img1.png'; //台灣
import PartnerSectionImg2 from '../../assets/img/partner-section-img2.png'; //交通部
import PartnerSectionImg3 from '../../assets/img/logo-nu.png'; //紐崔萊
import PartnerSectionImg4 from '../../assets/img/partner-section-img4.png'; //KPLUS
import PartnerSectionImg5 from '../../assets/img/partner-section-img5.png';
import PartnerSectionImg6 from '../../assets/img/partner-section-img6.png';
import PartnerSectionImg7 from '../../assets/img/partner-section-img7.png'; //名衍
import PartnerSectionImg8 from '../../assets/img/partner-section-img8.png'; //雪霸運動
import PartnerSectionImg9 from '../../assets/img/partner-section-img9.png'; //日管處
import PartnerSectionImg10 from '../../assets/img/partner-section-img10.png'; //Titan
import PartnerSectionImg11 from '../../assets/img/partner-section-img11.png'; //Bone
import PartnerSectionImg12 from '../../assets/img/partner-section-img12.png'; //南投縣
import PartnerSectionImg13 from '../../assets/img/partner-section-img13.png'; //推廣協會
import PartnerSectionImg14 from '../../assets/img/partner-section-img14.png'; //Oakley
import PartnerSectionImg15 from '../../assets/img/partner-section-img15.jpg'; //Oakley


const advisory = [
    {url: "https://www.moenv.gov.tw/", img: "/img/partner/ministry_of_env.png"},
    {url: "https://www.taiwan.net.tw/", img: "/img/partner/tw_waves_of_wonder.png"},
    {url: "https://www.nantou.gov.tw/", img: "/img/partner/nantou_gov.png"},
    {url: "https://www.aso.fr/en", img: "/img/partner/aso.png"},
];

const partners = [
    {url: "https://www.kplus-helmet.com/", img: "/img/partner/partner-section-img4.png"},
    {url: "https://www.titan-tech.com.tw/", img: "/img/partner/partner-section-img10.png"},
    {url: "https://www.boneshop.com/tw/", img: "/img/partner/partner-section-img11.png"},
    {url: "https://tw.oakley.com/zh", img: "/img/partner/partner-section-img14.png"},
    {url: "https://www.mommyhappy.com/", img: "/img/partner/partner-section-img6.png"},
    {url: "https://www.deya.tw", img: "/img/partner/partner-section-img15.jpg"},
    // {url: "https://www.aso.fr/en", img: "/img/partner/aso.png"},
    {url: "#", img: "/img/partner/black_girl.png"},
    // {url: "https://body-marketing.com.tw/", img: "/img/partner/body.png"},
    {url: "https://www.castrol.com/", img: "/img/partner/castrol.png"},
    {url: "https://www.evaair.com/zh-tw/index.html", img: "/img/partner/eva_air.png"},
    {url: "https://factorbikes.com/", img: "/img/partner/factor.png"},
    // {url: "https://www.je22.cc/", img: "/img/partner/je22.png"},
    {url: "https://lwhklab.com.tw/", img: "/img/partner/Leeuwenhoek.png"},
    // {url: "https://www.moenv.gov.tw/", img: "/img/partner/ministry_of_env.png"},
    // {url: "https://www.nantou.gov.tw/", img: "/img/partner/nantou_gov.png"},
    // {url: "https://www.facebook.com/p/%E4%B8%AD%E8%8F%AF%E6%B0%91%E5%9C%8B%E9%81%8B%E5%8B%95%E8%88%87%E4%BC%91%E9%96%92%E5%8D%94%E6%9C%83-100087473736077/", img: "/img/partner/roc_sport_communitry.png"},
    // {url: "https://shop.santinisms.tw/", img: "/img/partner/santini.png"},
    // {url: "https://sherpa-sports.co/", img: "/img/partner/sherpa_sport.png"},
    {url: "https://www.shimano.com/en/", img: "/img/partner/shimano.png"},
    // {url: "https://www.facebook.com/sunmoonlaketw", img: "/img/partner/sun_moon_lake.png"},

    {url: "https://www.volvo.com/en/", img: "/img/partner/volvo.png"},
    {url: "https://www.goldennet.com.tw/tw", img: "/img/partner/goldennet.png"},
    {url: "https://viva.lianhwa.com.tw", img: "/img/partner/viva.png"}
];

const PartnerSection = ({language}) => {
    return (
        <section className="partner-section">
            <Container>
                <Row className='align-items-center text-center py-2'>
                    {
                        language === "Chinese" ? <h1><span>合作夥伴</span></h1> : <h1><span>OFFICIAL PARTNERS</span></h1>
                    }
                </Row>
                <Row className='align-items-center text-center my-4'>
                    <h2>指導單位 | Advisory Organization</h2>
                    {advisory.map((item, index) => {
                        return (
                            <Col key={item.url} size={12} xs={6} md={3} sm={6}>
                                <a href={item.url} target='_blank' rel='noreferrer noopener'>
                                    <div className="img-bx">
                                        <img className='w-100' src={item.img} alt="" />
                                    </div>
                                </a>
                            </Col>)
                    })}
                </Row>
                <Row className='align-items-center text-center my-4'>
                    <h2>主辦單位 | Organier</h2>
                    <Col xs={6}>
                        <div className="img-bx">
                            <img src={PartnerSectionImg13} alt="" />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <a href="https://body-marketing.com.tw/" target='_blank' rel='noreferrer noopener'>
                            <div className="img-bx">
                                <img src={PartnerSectionImg7} alt="" />
                            </div>
                        </a>
                    </Col>
                </Row>
                <Row className='align-items-center text-center my-4' rel='noreferrer noopener'>
                    <h2>協辦單位 | Co-organizer</h2>
                    <Col xs={6}>
                        <a href="https://sherpa-sports.co/" target='_blank' rel='noreferrer noopener'>
                            <div className="img-bx">
                                <img className='w-100' src={PartnerSectionImg8} alt="" />
                            </div>
                        </a>
                    </Col>
                    <Col xs={6}>
                        <a href="https://admin.taiwan.net.tw/sunmoonlake/" target='_blank' rel='noreferrer noopener'>
                            <div className="img-bx">
                                <img src={PartnerSectionImg9} alt="" />
                            </div>
                        </a>
                    </Col>
                </Row>

                <Row className='align-items-center text-center my-4'>
                    <h2>冠名贊助 | Presented by</h2>
                    <Col xs={12} className='center'>
                        <a href="https://www.nutrilite.com.tw/" target='_blank' rel='noreferrer noopener'>
                            <div className="img-bx">
                                <img className='w-100 my-5 logo-nu' src={PartnerSectionImg3} alt="" />
                            </div>
                        </a>
                    </Col>
                </Row>

                <Row className='align-items-center text-center my-4'>
                    <h2>合作夥伴 | Official Partners</h2>
                    {partners.map((item, index) => {
                        return (
                            <Col key={item.url} size={12} xs={6} md={3} sm={6} className='py-4'>
                                <a href={item.url} target='_blank' rel='noreferrer noopener'>
                                    <div className="img-bx">
                                    <img className='w-75' src={item.img} alt="" />
                                    </div>
                                </a>
                            </Col>)
                    })}
                </Row>
            </Container>
        </section>
    )
}

export default PartnerSection
